.containerErrorPage {
    padding: 80px 40px;
    background-image: url('/images/404.png');
    background-size: auto 50%;
    height: 550px;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-color: #000;

    @media (max-width: 1025px) {
        background-size: auto 40%;
    }

    @media (max-width: 676px) {
        background-size: auto 35%;
        height: 390px;
    }

    @media (max-width: 576px) {
        background-size: auto 30%;
    }

    @media (max-width: 426px) {
        background-size: auto 20%;
        height: 360px;
    }

    .containerErrorContent {
        display: flex;
        justify-content: flex-end;
        margin-right: 15%;
        margin-top: 40px;
        
        .containerTitleError {
            color: #fff;

            p {
                font-size: 13px;
                font-weight: 200;
            }
            a {
                font-size: 20px;
                font-weight: 600;
                color: #fff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
