footer {
    color: #000;
    padding: 50px 10%;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, .06);
    padding: 3% 7% 1.5% 7%;
    
    .containerFooter {
        display: flex;
        //justify-content: space-between;

        @media (max-width: 820px) {
            flex-direction: column;
        }

        .contactInfo {
            margin-right: 10%;
            @media (max-width: 1024px) {
                margin-right: 5%;
            }
            @media (max-width: 769px) {
                order: 5;
            }

            .logo-footer {
                img {
                    max-height:70px;
                }
            }

            p {
                font-size: 14px;
                font-style: normal;
            }

            .rsList {
                font-size: 20px;
                
                a {
                    margin-right: 10px;

                    i {
                        color: #000;
                    }
                }
            }
        }

        .navFooter, .navJuridic, .navFooterOther {
            margin: 0;
            margin-right: 19%;

            @media (max-width: 1440px) {
                margin-right: 10%;
            }

            @media (max-width: 769px) {
                order: 5;
            }

            h3 {
                margin: 0 0 15px 0;
                font-size: 18px;
                font-weight: 600;
                font-style: normal;

                @media (max-width: 769px) {
                    margin: 15px 0 0 0;
                }
            }

            ul {
                list-style: none;
                margin: unset;
                padding: 0;

                li {
                    margin-bottom: -3px;

                    &:hover {
                        text-decoration: underline;
                    }

                    a {
                        font-size: 14px;
                        color: #000;
                        font-weight: 500;
                        text-decoration: none;
                        font-style: normal;
                    }
                }
            }

            @media (max-width: 1024px) {
                margin-right: 5%;
            }
        }

        .navFooterOther {
            @media (max-width: 769px) {
                margin-bottom: 20px;
            }
        }

        .newsletterContainer {

            @media (max-width: 820px) {
                display: flex;
                padding: 30px;
                margin-right: 0;
                order: 0;
            }

            @media (max-width: 769px) {
                padding: 30px 0;
            }

            .btn-footer {
                @media (max-width: 769px) {
                    width: 95%;
                }
            }
        }
    }

    #footerLogo {
        float: right;
        margin-bottom: 10px;
        margin-top: -38px;

        @media (max-width: 769px) {
            margin-top: 5px;
        }
    }

}
