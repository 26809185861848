.oneNews {
    display: flex;
    flex-direction: column;
    padding-top: 35px;

    div.containerTitle {
        display: inline-flex;
        margin: 0 10% 20px 10%;
        align-items: center;

        @media (max-width: 1025px) {
            margin: 0 30px 20px 30px;
        }

        a {
            text-decoration: none;
            width: 70px;
            max-height: 100px;
            align-self: flex-start;

            .chevronLeft {
                margin: 1% 10px 0 0;
                background-image: url("/images/return.png");
                width: 100%;
                height: 70px;
                background-size: cover;
                background-repeat: no-repeat;

                @media (max-width: 920px) {
                    width: 50%;
                    height: 50px;
                }
            }
        }

        h1 {
            flex: 3;
            font-size: 70px;
            line-height: 74px;
            margin-right: 10px;
            padding-right: 10px;
            font-weight: 900;
            flex: 6;
            word-break: break-word;

            @media (max-width: 1025px) {
                font-size:  32px;
                line-height: 38px;
            }
        }

        p {
            font-size: 18px;
            align-self: flex-start;
            margin-left: 10px;
            font-weight: 500;
            color: #909090;
            flex: 2;
            text-align: right;
            padding-left: 10px;
        }
    }

    .blockNews {
        margin: 0 10% 40px 10%;
        display: flex;

        @media (max-width: 769px) {
            margin: 10px 30px;
        }

        @media (max-width: 520px) {
            margin: 10px;
        }
        
        .imgBlockNews {
            max-width: 565px;
            width: 100%;
            //height: 605px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top;
            min-height: 465px;

            @media (max-width: 1025px) {
                background-size: cover;
                //height: 220px;
                align-self: center;
                margin-bottom: 10px;
                max-width: 100%;
            }
            
        }

        .article {
            margin-left: 20px;
            width: 65%;

            @media (max-width: 1025px) {
                width: 100%;
                margin-left: 0;
            }

            @media (max-width: 769px) {
                margin-left: 0;
            }

            article.newsText {
                margin: 0 0 0 40px;
                font-size: 14px;
                font-weight: 500;

                @media (max-width: 1025px) {
                    margin-left: 0;
                }

                @media (max-width: 769px) {
                    margin: 0 10px;
                }

                p {
                    margin: 0px;
                    padding: 0px;
                }
            }

            a, .subGame {
                text-decoration: none;
                display: block;
                margin: 20px auto;

                .btnSkew {
                    width: 230px;
                    margin: 0 auto;

                    div {
                        height: 45px;
                    }
                }
            }

            article p a {
                display: inline;
            }

            .containerForm {
                display: flex;
                flex-direction: column;
                flex: 1;
    
                form {
                    display: block;
                    margin: 20px 40px 20px 0 ;
    
                    @media (max-width: 520px) {
                        margin: 0 5%;
                    }
    
                    label {
                        display: flex;
                        width: 35%;
                        float: left;
                        justify-content: flex-end;
                        margin-bottom: 20.5px;
                        height: 40px;
                        align-items: center;
                        text-align: right;
    
                        @media (max-width: 769px) {
                            display: none;
                        }
                    }
    
                    input, textarea {
                        padding: 0;
                        display: flex;
                        width: 61%;
                        float: right;
                        resize: vertical;
    
                        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: #cecece;
                            opacity: 1; /* Firefox */
                        }
                        
                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: #cecece;
                        }
                        
                        &::-ms-input-placeholder { /* Microsoft Edge */
                            color: #cecece;
                        }
    
                        @media (max-width: 769px) {
                            width: 95%;
                            margin: 20px 0;
                        }
                    }
    
                    input {
                        border: none;
                        border-bottom: 1px solid #cecece;
                        margin-bottom: 20px;
                        height: 40px;
                    }
    
                    textarea {
                        border: 1px solid #cecece;
                        margin-bottom: 20px;
                        padding: 5px;
                        width: 59%;
    
                        @media (max-width: 769px) {
                            width: 95%;
                            margin: 20px 0;
                        }
                    }
                }
    
    
                .btnSkew {
                    display: flex;
                    width: 45%;
                    margin: 20px 20px 20px 39%;
    
                    @media (max-width: 769px) {
                        width: 60%;
                        margin: 20px auto;
                    }
    
                    div {
                        flex: 1;
                        height: 45px;
                    }
                }
    
                .btnSend {
                    visibility: hidden;
                }
    
                .alert-success {
                    color: #90C97E;
                    transition: .3s;
                }
            }


        }


        @media (max-width: 1025px) {
            flex-direction: column;
        }

        .video {
            display: flex;
            justify-content: center;

            iframe {
                min-width: 44vw !important;
            }
        }
    }
}
