.containerModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);

    .modal {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 70px auto 20px auto;
        background: #fff;
        border-radius: 4px;
        max-width: 780px;
        padding-bottom: 50px;
        padding-top: 0;

        @media (max-width: 425px) {
            max-width: 90%;
        }

        .logo img{
            margin: 10px auto;
            max-height:55px;
        }

        h3 {
            font-size: 25px;
            width: 90%;
            margin: 10px auto 20px auto;
            font-weight: 700;
        }

        input {
            width: 70%;
            height: 40px;
            padding: 5px 20px;
            font-size: 18px;
            border: 1px solid #d6d6d6;
            color: #000;
            border-radius: 4px;
        }

        .btnSkew {
            width: 40%;
            margin: 20px auto 5px auto;
        }

        a.knowMore {
            display: inline-block;
            font-weight: 500;
            margin: 10px auto 50px auto;
            font-size: 16px;
            color: #000;
            text-decoration: none;
        }

        p {
            margin: 0 20px;
            max-height: 200px;
            overflow: auto; 
        }
    }
}

#rgpdModal {
    display: none;

    .modal {
        display: flex;
        margin-top: 100px;
        padding: 20px 0;

        @media (max-width: 769px) {
            margin: 20px;
        }

        .containerBtn {
            margin: 20px 0;
            display: flex;
            justify-content: center;

            @media (max-width: 520px) {
                flex-direction: column;
            }

            .btnSkew {
                margin: 0 10px;

                @media (max-width: 520px) {
                    width: 70%;
                    margin: 10px auto;

                }
            }

            .denied {
                //background: #d6d6d6;

                div span {
                    //color: #d6d6d6;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    
    .rgdpUseLink {
        color: #000;
        margin-bottom: 60px;
    }
}

#steeringCommitteeModal {
    overflow: auto;
    .modal {
        max-width: 55%;
        border-radius: 4px;
        padding-bottom: 10px;
        padding-top: 10px;
        //margin-top: 4%;

        @media (max-width: 1600px) {
            max-width: 65%;
        }

        @media (max-width: 765px) {
            max-width: 90%;
        }


        .containerInterview {
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 15px;
            flex-direction: column;
            justify-content: center;
            font-size: 21px;
            
            .containerContInter {
                display: flex;
                margin: 0 90px;
    
                @media (max-width: 1440px) {
                    flex-direction: column;
                    margin: 0 20px;
                }
    
                .containerImgSkew2 {
                    display: flex;
                    overflow: hidden;
                    width: 340px;
                    padding: 10px;
                    z-index: 0;
                    flex-direction: column;
                    align-items: center;
        
                    @media (max-width: 1440px) {
                        width: 95%;
                        flex: inherit;
                    }
            
                    .imgSkew {
                        display: flex;
                        background-image: url('/images/img-home.jpeg');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: top;
                        clip-path: polygon(0px 30px, 270px 30px, 340px 260px, 100px 260px, 70px 260px);
                        align-self: center;
                        width: 340px;
                        height: 285px;


                        @media (max-width: 520px) {
                            width: 270px;
                            clip-path: polygon(0px 30px, 210px 30px, 270px 260px, 100px 260px, 70px 260px)
                        }
                       
                    }
        
                    p {
                        margin: 0;
                        margin-left: 20%;
                        margin-bottom: 20px;
                        font-weight: 500;
        
                        span {
                            font-weight: 700;
                        }
                    }
                }
    
                .containerQuestionAnswer {
                    justify-content: center;
                    flex: 1;
                    margin: 10px;
                    display: flex;
                    text-align: justify;
                    flex-direction: column;
                    justify-content: flex-start;
                    padding-top: 40px;

                    @media (max-width: 1440px) {
                        padding-top: 0;
                    }
    
                    article {
                        font-weight: 400;
                        margin-bottom: 0;
                        font-size: 14px;

                        p {
                            max-height: unset;
                        }
                    }
                }
            }
    
            h3 {
                font-size: 14px;
                margin: 0;

                @media (max-width: 1440px) {
                   margin-bottom: 10px;
                }
            }

            p {
                font-size: 14px;
                margin: 0;
            }
        }
    }
}

#newsletters {
    img {
        margin:40px auto 0px auto;
        max-height: 100px;
    }
}