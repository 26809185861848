.legalMentionPage {
    display: flex;
    flex-direction: column;

    .containerMentions {
        margin: 60px 120px;

        @media (max-width: 769px) {
            margin: 20px 60px;
        }

        h2 {
            text-transform: uppercase;
            font-size: 16px;
        }

        p {
            margin-bottom: 20px;
        }
    }

}
