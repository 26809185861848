.sectionLastProductions {
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
            h2 {
                width: 80%;
                margin: 3.5% 7% 2.5% 7%;
                font-size: 70px;
                color: #fff;
                text-transform: uppercase;
                line-height: 58px;
                font-weight:800;
                font-style: normal;
        
                @media (max-width: 769px) {
                    margin: 30px;
                    font-size: 50px;
                    line-height: 48px;
                }
        
                @media (max-width: 520px) {
                    font-size: 35px;
                    line-height: 38px;
                    width: auto;
                }
            }
    }

    .containerLastProduction {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;

        @media (max-width: 1224px) {
            display: none;
        }

        a {
            display: flex;
            flex: 1;
            text-decoration: none;
            transform: skewX(20deg);
            //transition-property: flex, opacity;
            transition: 1s ease-in-out;
            //transition-duration: 1s;

            &:hover {
                flex: 1.1;
                //transition-delay: .3s;
                //transition-property: width, opacity;

                .cardSkew {
                    opacity: 1;
                    border-image-source: linear-gradient(85deg, #7500ff, #f3005e);
                    border-left: 2px solid;
                    border-right: 2px solid;
                }
            }

            .cardSkew {
                flex:1;
                overflow: hidden;
                opacity: .3;
                border-left: 0px solid;
                border-right: 0px solid;
                border-image-slice: 1;
                border-color: #000;
                width: 0;
                pointer-events: none;
                //transition-duration: 1s;
    
                .slideSkew {
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: calc(11vh + 200%);
                    transform: skew(-20deg) translate(-35%, 0px);
                    flex: 1;
                    background-image: url('/images/img-home.jpeg');
                    height: 75vh;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    text-align: center;
    
                    p {
                        opacity: 0;
                    }
                }
            }
        }

        .textCard {
            pointer-events: none;
            user-select: none;
            transition: .7s ease-in-out;
            position: absolute;
            width: 260px;
            height: 0;
            opacity: 0;
            color: #fff;
            text-transform: uppercase;
            font-size: 19px;
            font-weight: 500;
            text-align: center;
            z-index: 1;
            font-style: normal;
        }
    }

    .lastProdMobile {
        display: none;
        width: 100%;
        max-height: 500px;

        .containerCardMobile {

            a {
                width: 100%;
            }
        }

        @media (max-width: 1224px) {
            display: block;

            .cardSkew {
                .slideSkew {
                    transform: skew(-20deg) translate(-45%, 0px)!important;
                }
            }
    
            .autoplayLastProd {
                width: auto;
            }

            .slick-current {
                .cardSkew {
                    opacity: 1!important;
                    border-image-source: linear-gradient(85deg, #7500ff, #f3005e);
                    border-left: 2px solid;
                    border-right: 2px solid;
                }

                .textCard {
                    opacity: 1!important;
                    top: 40%;
                    margin-left: -50px;

                    @media (max-width: 376px) {
                        margin-left: -120px;
                    }
                }
            }
        }
        
    }

    .lastProdDesk {
        display: block;

        @media (max-width: 1224px) {
            display: none;
        }

        .autoplayLastProdArrow {
            .slickPrev {
                font-size: 40px;
                color: white;
                position: absolute;
                bottom: 50%;
                left: 0;
                margin-left: 30px;
                z-index: 999;
            }
            .slickNext {
                font-size: 40px;
                color: white;
                position: absolute;
                margin-right: 30px;
                bottom: 50%;
                right: 0;
            }
        }
    }
}
