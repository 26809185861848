.knowMore {
    display: flex;
    flex-direction: column;

    h3.title {
        text-align: center;
        font-size: 19px;
        font-weight: 900;
        margin-left: -9%;


        @media (max-width: 426px) {
            margin-left: 0;
        }
    }

    .containerKnowMore {
        display: flex;
        margin: 20px 10% 5% 10%;
        color: #000;
        justify-content: center;

        a {
            text-decoration: none;
            color: #000;
        }

        @media (max-width: 769px) {
            flex-direction: column;
            margin: 40px 30px;
        }

        @media (max-width: 520px) {
            margin: 40px 10px;
        }

        .actualite {
            margin: 15px;
            display: flex;
            flex-direction: column;
            flex: 1;
            align-items: center;
            max-width: 300px;

            @media (max-width: 769px) {
                max-width: 100%;
            }

            .cover {
                width: 100%;
                align-self: center;
                height: 400px;
                background-size: cover;
                background-position: center;
                max-width: 300px;
            }


            .containerInfo {
                display: flex;
                flex: 1;
                flex-direction: column;
                margin: 10px;
                min-width: 100%;

                title {
                    font-size: 17px;
                    font-weight: 800;
                    font-size: 21px;

                    @media (max-width: 993px) {
                        font-size: 17px;
                    }
                }

                h2 {
                    margin-bottom: 5px;
                    margin-top: 5px;
                    word-break: break-word;
                    font-size: 17px;
                    font-weight: 800;
                }
    
                .createdAt {
                    color: #909090;
                    margin-top: 5px;
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 400;
                }

                .contents {
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    max-height: 91px;
                    overflow: hidden;
                }
    
                .link {
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    text-decoration: underline;
                }
            } 
        }
    }
}
