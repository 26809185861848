.sectionDocuments {
    display: flex;
    flex-direction: column;
    margin: 20px 10% 40px 10%;
    background: #fff;

    @media (max-width: 769px) {
        margin: 20px 60px;
    }

    @media (max-width: 476px) {
        margin: 20px 30px;
    }

    .titleProd {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
    }

    .containerDocuments {
        display: flex;
        flex-direction: column;
        
        .document {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #cecece;

            p {
                flex: 1;
            }

            .iconsActions {
                display: flex;
                justify-content: flex-end;
                flex: 1;
                align-items: center;

                img {
                    margin: 0 20px;
                }
                img:first-child {
                    height: 21px;
                }
                img:last-child {
                    height: 27px;
                }
            }
        }
    }
}
