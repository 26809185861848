.sectionPhotos {
    display: flex;
    flex-direction: column;
    margin: 20px 10% 40px 10%;
    background: #fff;

    @media (max-width: 769px) {
        margin: 20px 60px;
    }

    @media (max-width: 476px) {
        margin: 20px 30px;
    }

    .titleProd {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
    }

    .autoplayPhotos {
        display: flex;
        align-items: center;
        margin: 20px auto 10px auto;
        width: 90%;

        .slick-track {
            display: flex;
        }
        
        i.fas {
            font-size: 35px;
            margin: 10px;
        }

        .slickImg {
            outline: none;
            display: flex;
            justify-content: center;
            align-self: flex-start;
            flex-direction: column;

            img {
                display: flex;
                margin: 10px;
                max-height: 400px;

                @media (max-width: 1025px) {
                    max-width: 300px;
                }
            }

            p {
                text-align: left;
                margin: 0px 10px 5px 10px;
                max-width: 265px;
            }
        } 
    }

    .copyrightGlobal {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 30px;
    }
}

#photosCarousel {
    .modal {
        width: 100%;
        background: none;
        max-width: 95%;
        margin-top: 0%;

        @media (max-width: 1442px) {
            margin-top: 14%;
        }
    }
    .closeArrowPhotosPop {
        right: 3%;
        position: absolute;
        top: 7%;
        color: #fff;
        font-size: 25px;
    }
    .autoplayPhotosPop {
        display: flex;
        align-items: center;
        
        i {
            font-size: 22px;
            color: #fff;
            align-self: center;
            margin: 0 8px;
            margin-top: 8%;
        }

        .slick-track {
            display: flex;
        }

        .slick-list {
            width: 100%;

            .slickImg {
                outline: none;
                display: flex;
                justify-content: center;
                align-self: center;
                flex-direction: column;
                height:100vh;
    
                img {
                    display: flex;
                    margin: 0 auto;
                    max-width: 95%;
                    max-height: 900px;

                    @media (max-width: 1441px) {
                        max-height: 500px;
                    }

                }

                p {
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                    margin-top: 20px;
                }
            }
        }
    }
}
