    .slick-prev:before {
    color: transparent;
  }
  #slick_partner .slick-next:before {
    color: transparent;
  }
  
  
  
    .slick-prev {
      background-image: url('/images/prev.png');
      background-repeat: no-repeat;
      opacity: 1;
      background-size: 60% 100%;
  }
  
    .slick-next {
      background-image: url('/images/next.png');
      background-repeat: no-repeat;
      opacity: 1;
      background-size: 60% 100%;
  }
  
    .slick-prev, 
    .slick-next {
      width: 80px;
      height: 45px;
  }
  
    .slick-prev {
      left: -140px;
  }
  
    .slick-next {
      right: -140px;
  }