.sectionAwards {
    display: flex;
    flex-direction: column;
    margin:20px 10% 40px 10%;
    background: #fff;

    @media (max-width: 769px) {
        margin: 20px 60px;
    }

    @media (max-width: 476px) {
        margin: 20px 30px;
    }

    .titleProd {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
    }

    div {
        display: flex;
        flex-wrap: wrap;
        
        span {
            font-weight: 600;
        }
    
        article {
            flex: 0 0 30%;
            margin: 1.5%;
            padding: 0px;

            p {
                margin:0px;
                padding:0px;
            }

            @media (max-width: 769px) {
                flex: 0 0 100%;
            }
        }
    }
}
