.btnSkew {
    display: block;
    height: 45px;
    width: calc(100% + 30px);
    padding: 3px;
    transform: skew(20deg);
    background: linear-gradient(to right, #5e00d4, #f00);
    text-align: center;
    border: none;
    cursor: pointer;

    div {
        display: block;
        text-align: center;
        background-color: #fff;

        span {
            display: block;
            height: 35px;
            padding: 10px 15px 0px 15px;
            transform: skew(-20deg);
            font-size: 15px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            text-transform: uppercase;
            color: #000;
            white-space: nowrap;
            text-decoration: none;
        }

        &:hover {
            background: none!important;

            span {
                color: #fff!important;
            }
        }
    }
}
