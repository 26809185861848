.contactPage {
    display: flex;
    flex-direction: column;

    @media (max-width: 920px) {
        flex-direction: column;
    }

    .containerContactInfo {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 60%;
        background: #000;
        color: #fff;
        clip-path: polygon(50% 0%, 80% 0%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
        z-index: 1;
        height: calc(100vh - 75px);    
        max-height: 700px;    

        @media (max-width: 921px) {
            width: 100%;
            clip-path: none;
            position: unset;
            max-height: 540px;    
        }

        .text {
            display: flex;
            flex-direction: column;
            margin: 60px 120px 120px 12.5%;

                @media (max-width: 921px) {
                    margin: 20px 20px 60px 20px;
                }

            article {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 300;

                @media (max-width: 1441px) {
                    font-size: 12px;
                    font-weight: 300;
                }
            }
    
            h1 {
                text-transform: uppercase;
                font-size: 55px;
                font-weight: 900;
                margin-bottom: 30px;

                @media (max-width: 921px) {
                    font-size: 45px;
                }
            }
    
            h2 {
                margin-bottom: 0;
                margin-top: 30px;
                font-weight: 500;

                @media (max-width: 921px) {
                    margin-top: 40px;
                }
            }
    
            p.makingProd {
                font-weight: 100;
                font-size: 30px;
                margin-bottom: 0;
    
                span {
                    font-weight: 700;
                }
            }

            .containerImg {
                max-width: 100px;
                
                img {
                    max-height: 80px;
                }
            }
        }

    }

    .containerMainInfo {
        height: calc(100vh - 75px);    
        max-height: 700px;    

        @media (max-width: 921px) {
            max-height: 840px;    
            height: 840px;    
        }
    }

    .map {
        //max-height: 733px;
        height: 100%;
        display: flex;
        width: 55%;
        margin-left: 45%;
        
        @media (max-width: 1441px) {
            // max-height: 642px;
            // height: 642px;
        }

        @media (max-width: 921px) {
            width: 100%;
            margin-left: 0;
            clip-path: none;
            position: unset;
            max-height: 300px;
            height: 300px;
        }

    }

    .containerInfoSup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 60px 7.5%;


        @media (max-width: 921px) {
            flex-direction: column;
            align-items: flex-start;
            margin: 20px;
            padding-bottom: 20px;
        }

        .info {
            width: 50%;
            h2 {
                text-transform: uppercase;
                font-size: 25px;
                font-weight: 900;
            }

            p {
                margin: 0;
                font-weight:700;
            }

            &:last-child {
                margin-left: 70px;
            }


            @media (max-width: 921px) {
                margin-left: 70px;
            }
        }


    }
}
