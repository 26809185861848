.sectionPropos {
    font-family: 'Poppins', sans-serif;
    display: flex;
    //background: #000;
    overflow: hidden;

    @media (max-width: 1025px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
    }

    .containerHome {
        margin: 3.5% 5% 4.5% 8%;
        display: flex;
        flex-direction: column;
        flex: 1;

        @media (max-width: 769px) {
            margin: 40px 30px;
            width: 90%;
        }

        @media (max-width: 521px) {
            margin-left: 0;
            margin-right: 0;
        }

        .title {
            color: #000;
            font-size: 70px;
            font-weight: 800;
            text-transform: uppercase;
            margin: 0;
            line-height: 70px;
            margin-bottom:30px;

            @media (max-width: 1441px) {
                margin-top: 10px;
            }

            @media (max-width: 769px) {
                margin-top: 0;
                font-size: 45px;
            }

            @media (max-width: 426px) {
                font-size: 35px;
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        .content {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin-bottom: 30px;

            p {
                margin:0px;
                padding:0px;
            }

            @media (max-width: 426px) {
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        a {
            text-decoration: none;

            @media (max-width: 769px) {
                order: 1;
            }

            .btnSkew {
                width: 264px;

                @media (max-width: 769px) {
                    margin-top: 30px;
                    width: 100%;
                }
            }

            @media (max-width: 426px) {
                margin-left: 30px;
                margin-right: 30px;
            }
        }
    }

    .containerImgSkew {
        display: flex;
        overflow: hidden;
        align-items: center;
        flex: 1;
        width: 500px;

        .imgSkew {
            display: flex;
            
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center left;
            clip-path: polygon(0 0, 370px 0, 415px 240px, 500px 730px, 130px 650px);
            flex: 1;
            align-self: center;
            height: 280px;
            margin-left: 28%;

            @media (max-width: 1441px) {
                margin-left: 10%;
            }
            @media (max-width: 1025px) {
                margin-left: 15%;
            }
            @media (max-width: 926px) {
                margin-left: 10%;
            }

            @media (max-width: 521px) {
                clip-path: polygon(40px 0px, 330px 0, 375px 280px, 560px 720px, 150px 650px);
            }
        }
    }

    .containerImgSkewMobile {
        display: none;
        margin-top: 50px;
        height: 220px;

        .imgSkew {
            display: flex;
            background-image: url('/images/img-home.jpeg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
            clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 100% 100%, 30% 110%);
            flex:1;
            align-self: center;
            height: 200px;
        
        }
        
        @media (max-width: 426px) {
            margin-top: 0;
            display: flex;
            margin-left: 30px;
        }
    }
}
