@import "../section/sectionWhoAreWe.scss";

.teamPageCase {
    .containerClassic {
        .teamPage .containerSpePropos {
            display: none;
        }
    }
}

.teamPageCase .containerClassic:nth-child(2) {
    .containerSpePropos {
        display: flex;
    }
}
