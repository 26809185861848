.sectionProposDuo {
    .containerSection {
        display: flex;
        flex-direction: column;
        flex: 1;
        
        .titleSection {
            display: flex;
            color: #000;
            font-size: 70px;
            font-weight: 800;
            text-transform: uppercase;
            margin: 3.5% 5% 30px 8%;

            @media (max-width: 1441px) {
                margin-top: 10px;
                font-size: 45px;
            }

            @media (max-width: 926px) {
                margin-bottom: 10px;
            }

            @media (max-width: 769px) {
                margin: 10px 30px;
            }

            @media (max-width: 426px) {
                font-size: 38px;
                margin-left: 30px;
                margin-right: 30px;
            }
        }
        
        .containerLine {
            display: flex;
            overflow: hidden;

            @media (max-width: 926px) {
                flex-direction: column;
            }
            //background-color: black;

            .containerLeft {
                flex: 1;
                transition: .3s;
                background: white;

                &:hover {
                    background-color: black;
                }
            }
            .containerRight {
                flex: 1;
                transition: .3s;
                background: white;

                &:hover {
                    background-color: black;
                }
            }
            
            .containerTextSection {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                padding: 60px 80px;
                /*background-color: red;*/
                transform: skewX(-20deg);
                transition: .3s;
                background: white;
                min-height: 280px;
                //justify-content: center;

                @media (max-width: 926px) {
                    transform: none;
                    padding: 40px 80px;
                } 

                @media (max-width: 426px) {
                    padding: 20px 40px;
                }

                h1 { 
                    margin: 0;
                }

                &:hover {
                    background-color: black;
                    color: white!important;

                    h1 {
                        color:white;
                    }

                    .btnSkew div {
                        background-color: black;

                        span {
                            color: white;
                        }
                    }

                    article {
                        color: white!important;
                    }
                }

                img {
                    display: flex;
                    max-width: 300px;
                    width: 100%;
                    min-height: 175px;
                    padding: 10px;
                }

                .containerSkew {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transform: skewX(20deg);

                    @media (max-width: 926px) {
                        transform: none;
                    } 

                    article {
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        margin-bottom: 30px;
                        max-height: 120px;
                        overflow: auto;
                        scrollbar-width: none;
                        max-width: 70%;
                        text-align: center;

                        @media (max-width: 769px) {
                            margin-bottom: 5px;
                        }

                        &::-webkit-scrollbar {
                            width: 0;  /* Remove scrollbar space */
                            background: transparent;
                        }
                        
                        p {
                            display: flex;
                        }
                    }

                    a {
                        text-decoration: none;
            
                        @media (max-width: 769px) {
                            order: 1;
                        }
            
                        .btnSkew {
                            width: 264px;
            
                            @media (max-width: 769px) {
                                margin-top: 30px;
                                width: 100%;
                            }
                        }
            
                        @media (max-width: 426px) {
                            margin-left: 30px;
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
}
