.sectionVideo {
    margin: 20px 10% 40px 10%;    
    display: flex;
    flex-direction: column;

    @media (max-width: 526px) {
        margin: 20px 0% 40px 0%;
    }

    .videoNormal {
        display: flex;
        order: 1;
        flex-wrap: wrap;

        @media (max-width: 1025px) {
            flex-direction: column;
        }

        .oneMedia {
            width:100%;
            flex: none !important;
            background-color:black;
            
            .imgVideo {
                height:450px !important;
            }
        }

        .videoContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 33%;
            margin: 10px 0;
            //background-color: #000;
            
            img:first-child {
                max-width: 95%;
                height: 300px;

                @media (max-width: 1700px) {
                    height:auto;
                }


                @media (max-width: 1025px) {
                    max-width: 400px;
                    margin: 30px 0;
                }

                @media (max-width: 526px) {
                    max-width: 320px;
                    height: unset;
                }
            }
            
            img:last-child {
                position: absolute;
                max-width: 4%;
            }
        }
    
        p {
            margin: 10px 0;
            font-size: 25px;
            font-weight: 600;
            text-transform: uppercase;
    
            @media (max-width: 769px) {
                margin: 20px 60px;
            }

            @media (max-width: 526px) {
                margin: 20px 40px;
            }
        }
    }

    .autoplayVideoProd {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 30px;
            margin: 10px;
        }

        .videoContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 10px;

            .imgVideo {
                max-width: 340px;

                @media (max-width: 526px) {
                    max-width: 310px;
                }
            }

            .inputModalPlayer {
                position: absolute;
            }
        }
    }

    .BA {
        order: 0;
    }
    
}

#videoPlayer {
    .modal {
        background: none;
        max-width: 1200px;
        margin-top: 7.5%;

        .closeArrowVideosPop {
            right: 3%;
            position: absolute;
            top: 5%;
            color: #fff;
            font-size: 25px;
        }
    }
}
