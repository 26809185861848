.partners {
    font-family: 'Poppins', sans-serif;

    .title {
        color: #000;
        font-size: 70px;
        font-weight: 800;
        line-height: 58px;
        text-transform: uppercase;
        margin: 0;
        padding: 3% 7% 1% 7%;
        text-align: left;
        font-style:normal;

        @media (max-width: 1200px) {
            font-size: 40px;
            line-height: 48px;
        }

        @media (max-width: 769px) {
            padding: 3% 5% 1% 5%;
            font-size: 50px;
            line-height: 48px;
        }

        @media (max-width: 521px) {
            font-size: 38px;
            line-height: 36px;
        }

        @media (max-width: 376px) {
            font-size: 30px;
            line-height: 28px;
        }
    }

    .autoplay{
        display: flex;
        padding: 1% 10%;
        align-items: center;
        height: 180px;

        @media (max-width: 769px) {
            margin: 0 30px;
        }

        @media (max-width: 521px) {
            margin: 0 10px;
        }

        .slick-list {
            width: 100%;
            flex:1;
        }

        i {
            font-size: 30px;
        }

        .slickPrev {
            margin-right: 40px;
            cursor: pointer;
        }

        .slickNext {
            margin-left: 40px;
            cursor: pointer;
        }
        a {
            text-decoration: none;
            outline: none!important;
                .slickPartners {
                    min-height: 75px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 115px;
                    justify-content: space-between;

                    p {
                        font-size: 14px;
                        color:black;
                        justify-self: flex-end;
                    }
            }
        }
        
        .slickPartners>img {
            display: flex;
            margin: auto;
            max-height: 65px;
            max-width: 100%;
            max-width: -moz-available;          /* WebKit-based browsers will ignore this. */
            max-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
            max-width: fill-available;
        }
    }   

    .partnerInfo {
        text-align: center;
        margin-bottom: 50px;
        
    }

}
