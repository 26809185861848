.sectionTitleHome {
    width: 100%;
    //min-height: 450px;
    background-color:black;
    overflow: hidden;

    /*
    @media (max-width: 769px) {
        max-height: 550px;
    }*/

    .containerTitleHome {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        position: relative;
        max-height: 450px;
        min-height: 450px;
        text-align: center;

        .text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50%;
            padding-left: 8%;
            text-align: left;

            @media (max-width: 769px) {
                padding-right: 12%;
                padding-left: 5%;
                width: inherit;
            }

            .titleInfo {
                font-weight: 700;
                font-size: 21px;
                color: #ff0000;
            }

            article {
                p {
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 70px;
                line-height: 58px;
                font-weight: 800;
                margin-bottom: 40px;

                @media (max-width: 921px) {
                    font-size: 48px;
                    line-height: 41px;
                }

                @media (max-width: 650px) {
                    font-size: 38px;
                }
            }

            .btnSkew {
                width: 250px;
                cursor: pointer;
        
                div {
                    background-color: #000;
                    color: #fff;
                    max-height: 45px;
                    
                    span {
                        padding: 13px 0;
                        color: #fff;
                    }
                }
            }

            .blackTitle {
                color:black;
            }
        
            .whiteTitle {
                color:white;
            }

        }

        img {
            height: auto;
            margin-bottom: -7px;
            width: 100%;
    
            @media (max-width: 921px) {
                clip-path: none;
                position: unset;
            }
        }

    }

    .containerTitleHomeVideo {
        color: #fff;
        position: relative;
        max-height: 450px;
        min-height: 450px;
        text-align: left;
        display: flex;
        padding: 0 5%;
        align-items: center;

        @media (max-width: 769px) {
            flex-direction: column;
            max-height: none;
        }

        .text {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;

            @media (max-width: 769px) {
                margin-top: 10px;
            }

            .titleInfo {
                font-weight: 700;
                font-size: 21px;
                color: #ff0000;
            }

            article {
                overflow: auto;
                margin: 0 0 20px 0;

                p {
                    font-weight: 500;
                }
            }

            h1 {
                text-transform: uppercase;
                font-size: 70px;
                line-height: 58px;
                margin-bottom: 10px;

                @media (max-width: 921px) {
                    font-size: 48px;
                    line-height: 51px;
                }

                @media (max-width: 650px) {
                    font-size: 35px;
                }
            }

            .btnSkew {
                width: 250px;
                cursor: pointer;
        
                div {
                    background-color: #000;
                    color: #fff;
                    max-height: 45px;
                    
                    span {
                        padding: 13px 0;
                        color: #fff;
                    }
                }

            }

            .blackTitle {
                color:black;
            }
        
            .whiteTitle {
                color:white;
            }

        }

        img {
            display: flex;
            margin-left: 20px;
            width: 50%;

            @media (max-width: 769px) {
                //margin: 40px 0 20px 0;
                width: 100%;
            }
        }

        .noText {
            flex: unset;
        }

        .noTextImg {
            width: 100%;
        }

        .containerImgVideo {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            max-height: 500px;

            .imgVideoInput {
                width: 70%;
                max-height: 400px;
            }
    
            .imgVideoInputIcon {
                position: absolute;
                width: 120px;
            }
        }


    }

    //Cas Pas de description
    .noDesc {
        min-height: auto;
        .text {
            .btnSkew {
                margin-top: 30px;
                cursor: pointer;
                
                div {
                    max-height: 45px;
                }
                @media (max-width: 650px){
                    margin-top:15px;  
                    width: 200px;
                    div {
                        span {
                            padding: 3px 0;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    a {
        text-decoration: none;
    }

}

.modalTitleHome {
    .modal {
        background: none;
        max-width: 950px;
    }
}

.sectionTitleEquipe {
    max-height: 580px;
    //display: none;

    .containerTitleHome, .containerTitleHomeVideo {
        max-height: 580px;
        min-height: 580px;

        .text {
            top: 50%;

            @media (max-width: 1024px){
                top: 50%;
            }
        }

        .text h1 {
            font-size: 70px;

            @media (max-width: 1441px){
                font-size: 60px;
            }
            
            @media (max-width: 1024px){
                font-size: 55px;
            }

            @media (max-width: 769px){
                font-size: 42px;
            }
        }
    }

    .noDesc {
        max-height: 580px;
        min-height: auto;
    }
}
