//ALL ACTUE
.listActualite {
    display: flex;
    flex-direction: column;

    h1.title {
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 70px;
        font-weight: 900;
        line-height: 55px;
        max-width: 100%;
        padding: 60px 10% 0px 10%;

        @media (max-width: 920px) {
            font-size: 50px;
            line-height: 47px;
            padding-left: 0;
        }

        @media (max-width: 520px) {
            font-size: 38px;
            line-height: 36px;
        }
    }

    .containerCont {
        display: flex;
        flex: 1;
        justify-content: space-around;
        margin: 20px 50px 30px 50px;

        @media (max-width: 920px) {
            flex-direction: column;
            margin: 20px 20px 30px 20px;
        }

        .contents {
            display: flex;
            flex: 1;
            align-self: center;
            margin: 0 2% 0 5%;
            padding: 0 15%;
        }

        .sortBy {
            margin-right: 5%;
            align-self: center;

            @media (max-width: 920px) {
                align-self: flex-end;
            }
        }
    }

    .containerActu {
        display: flex;
        flex-wrap: wrap;
        max-width: 80%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;

        @media (max-width: 1560px) {
            max-width: 90%;
            width: 90%;
        }

        @media (max-width: 1080px) {
            max-width: 100%;
            width: 100%;
        }

        &:after {
            content: "";
            width: 320px;
        }

        .columnActu {
            width: 100%;
            position: relative;
            
            @media (min-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }

            @media (min-width: 769px) {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (min-width: 992px) {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
            }


            @media (min-width: 1440px) {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
            }


            a {
                max-width: 320px;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                text-decoration: none;

                .actue {
                    margin-bottom: 30px;
                    width: 95%;
                    min-width: 260px;

                    @media (max-width: 576px) {
                        width: 100%;
                    }
                    
                    div.containerBackgroundImg {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 0px;
                        padding-bottom: 5px;
                        height: 430px;
                        max-height: 430px;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        background-position: center !important;
                        transition: .3s;

                        &:hover {
                            box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, .2);
                            transform: scale(1.02);
                        }
                    }
                    
                    .infoActu {
                        max-width: 320px;
                        // width: 95%;

                        // @media (max-width: 576px) {
                        //     width: 100%;
                        // }
                    }

                    .createdAt, .title, .contents, .knowMore{
                        align-self:flex-start;
                        //padding: 5px;
                        color: black;
                        font-size: 14px;
                    }

                    .title {
                        font-weight: 900;
                        margin-bottom: 0px;
                        margin-top: 15px;
                        font-size: 21px;
                        padding-bottom: 0px;
                        word-break: break-word;
                    }

                    .createdAt {
                        margin: 0;
                        padding-top:0px;
                        padding-bottom:5px;
                        color: #909090;
                    }

                    .contents {
                        margin-bottom: 0;
                        max-height: 86px;
                        overflow: hidden;
                    }

                    .knowMore {
                        text-decoration: underline;
                        margin: 5px 0 0 0;
                        color: #000;
                    }
                }
            }
        }
    }
}




//ALL NEWS
/*
.listActualite {
    display: flex;
    flex-direction: column;
    padding: 60px 10% 40px 10%;

    h1.title {
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 70px;
        font-weight: 900;
        line-height: 55px;
        max-width: 100%;
        //overflow: hidden;

        @media (max-width: 993px) {
            font-size: 52px;
        }

        @media (max-width: 520px) {
            font-size: 38px;
        }
    }

    @import "../layout/sortBy.scss";

    .containerList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 30px auto 0 auto;
        align-items: flex-start;
        //justify-content: center;

        &:after {
            content: "";
            flex: auto;
            //margin-left: 20px;
        }

        .columnActu {
            display: flex;
            justify-content: center;

            @media (min-width: 576px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }

            @media (min-width: 769px) {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (min-width: 992px) {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
            }


            @media (min-width: 1440px) {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
            }
            
            a {
                text-decoration: none;
                display: flex;
                justify-content: center;
            
                .actualite {
                    flex-direction: column;
                    margin: 20px;
                    max-width: 320px;
                    display: flex;
                    text-decoration: none;
                    border-radius: 4px;
                    transition: .3s;
                    flex: 1;
                    width: 95%;
                    min-width: 270px;

                    @media (max-width: 576px) {
                        width: 100%;
                    }

                    .infoActu {
                        max-width: 320px;
                        // width: 95%;

                        // @media (max-width: 576px) {
                        //     width: 100%;
                        // }
                    }

                    .createdAt, .title, .contents, .knowMore{
                        align-self:flex-start;
                        //padding: 5px;
                        color: black;
                        font-size: 14px;
                    }

                    .title {
                        font-weight: 900;
                        margin-bottom: 0px;
                        margin-top: 15px;
                        font-size: 21px;
                        padding-bottom: 0px;
                        word-break: break-word;
                    }

                    .createdAt {
                        margin: 0;
                        padding-top:0px;
                        padding-bottom:5px;
                        color: #909090;
                    }

                    .contents {
                        margin-bottom: 0;
                        max-height: 86px;
                        overflow: hidden;
                    }
                    
                    .cover {
                        max-width: 320px;
                        height: 420px;
                        background-repeat: no-repeat !important;
                        background-size: cover !important;
                        background-position: center !important;
                        transition: .3s;
                        // width: 95%;

                        // @media (max-width: 576px) {
                        //     width: 100%;
                        // }

                        @media (max-width: 576px) {
                            width: 100%;
                        }

                        &:hover {
                            box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, .2);
                            transform: scale(1.02);
                        }
                    }

                    .knowMore {
                        text-decoration: underline;
                        margin: 5px 0 0 0;
                        color: #000;
                    }
                }
            }
        }
    }   
}
*/