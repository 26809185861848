.sectionPress {
    display: flex;
    flex-direction: column;
    padding: 20px 10% 40px 10%;
    background: #efefef;

    @media (max-width: 769px) {
        padding: 20px 60px;
    }

    @media (max-width: 476px) {
        margin: 20px 30px;
    }
    
    .titleProd {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
    }

    .articlePress {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        p {
            margin: 20px 10px;
            width: 31%;

            @media (max-width: 1072px) {
                width: 47%;
            }

            @media (max-width: 833px) {
                width: 100%;
            }
        }
    }
}
