div.sortBy {
    align-self: flex-end;

    .sortByContainer {
        margin-right: 20px;
    
        span {
            margin: 0 10px;
            font-size: 15px;
            text-transform: uppercase;
        }
    }
    
    .sortBySelection {
        display: none;
        width: 100px;
        height: 80px;
        background: #fff;
        position: absolute;
    
        ul {
            margin: 0 auto;
            padding: 0;
            display: flex;
            align-self: center;
            flex-direction: column;
            list-style: none;
            text-transform: uppercase;
            font-size: 16px;
    
            li {
                align-self: center;
    
                a {
                    text-decoration: none;
                    color: #000;
                }
            } 
        }
    }
    
    .showSortBy {
        display: flex;
        z-index: 1;
    }
}
