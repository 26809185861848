.prodCard {
    display: flex;
    flex-direction: column;

    div.containerTitle {
        display: inline-flex;
        margin: 3% 10% 0 10%;
        align-items: center;

        @media (max-width: 1025px) {
            margin: 20px 30px;
        }

        @media (max-width: 425px) {
            margin: 20px 10px;
        }

        a {
            text-decoration: none;
            width: 70px;
            max-height: 100px;
            align-self: flex-start;

            .chevronLeft {
                margin: 1% 10px 0 0;
                background-image: url("/images/return.png");
                width: 100%;
                height: 70px;
                background-size: cover;
                background-repeat: no-repeat;
            }
        }


        h1 {
            flex: 3;
            font-size: 48px;
            line-height: 62px;
            margin-right: 10px;
            margin-left: 10px;
            font-weight: 800;
            word-break: break-word;

            @media (max-width: 1025px) {
                font-size:  35px;
                line-height: 33px;
            }

            @media (max-width: 425px) {
                font-size: 27px;
                line-height: 25px;
            }
        }

        p {
            font-size: 18px;
            align-self: flex-end;
            margin-left: 10px;
            font-weight: 500;
            color:#909090;
        }
    }

    .blockNews {
        margin: 3% 10% 40px 10%;
        display: flex;

        @media (max-width: 769px) {
            margin: 40px 30px;
        }

        @media (max-width: 520px) {
            margin: 20px 10px;
        }

        .imgBlockNews {
            max-width: 525px;
            width: 100%;
            height: 745px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            @media (max-width: 1025px) {
                align-self: center;
                margin-bottom: 10px;
                max-width: 100%;
            }
            
        }

        .article {
            display: flex;
            flex-direction: column;
            margin: 0 50px;
            width: 55%;

            @media (max-width: 1025px) {
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
            }

            h2 {
                margin-bottom: 0;
                margin-top: 0;
                font-size: 24px;
                line-height: 21px;
                text-transform: uppercase;
                //margin-top: 10px;
                font-weight: 800;
            }

            .prodText, ul li, p {
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
            }

            p {
                margin: 0px;
                padding: 0px;

                span {
                    font-weight: 700;
                }
            }

            a {
                text-decoration: none;
                display: block;
                margin: 20px auto;
                width: 320px;

                .btnSkew {
                    width: 300px;
                    align-self: center;
                }
            }

            article p a {
                display: inline;
            }
        }


        @media (max-width: 1025px) {
            flex-direction: column;
        }
    }

    .cmNews {
        .containerImgTitle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            align-items: center;

            .imgBlockNews {
                max-width: 450px;
                height: 400px;
            }
            h1 {
                text-align: center;
            }
        }
    }
}
